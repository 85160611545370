// @ts-nocheck
import React, { useState } from "react";
import { Container } from "components/Container/Container";
import { CSSTransition } from "react-transition-group";

import { ReactComponent as Union } from "../../assets/icons/Union.svg";

import "./OurServices.scss";

interface Props {
  services: {
    description: string;
    title: string;
    services: {
      description: string;
      title: string;
      image: string;
    }[];
  };
}

export const OurServices = (props: Props) => {
  const [open, setOpen] = useState("");
  const { services } = props;

  const handleClick = (value: string) => {
    if (open !== value) {
      setOpen(value);
      return;
    }

    if (open === value) {
      setOpen("");
      return;
    }
  };

  return (
    <div className="OurServices">
      <Container>
        <div className="OurServices__wrapper">
          <h1>{services?.title}</h1>
          <p className="OurServices__wrapper-subtitle">{services?.description}</p>
          <div className="OurServices__wrapper_items">
            {services.services?.map((item, index) => {
              return (
                <div key={`${item.title}-${index}`}>
                  <div
                    onClick={() => handleClick(item.title)}
                    data-opened={open === item.title}
                    className="OurServices__wrapper_items_item"
                  >
                    <div className="OurServices__wrapper_items_item--left">
                      <div className="OurServices__wrapper_items_item--left-phone">
                        <img
                          style={{ width: 24, height: 24 }}
                          alt="img"
                          src={item.image}
                        />
                      </div>
                      <span>{item.title}</span>
                    </div>
                    <div className="OurServices__wrapper_items_item--right">
                      <div className="OurServices__wrapper_items_item--right-union">
                        <Union />
                      </div>
                    </div>
                  </div>
                  <CSSTransition
                    in={open === item.title}
                    timeout={200}
                    classNames="animation"
                    unmountOnExit
                  >
                    <div className="OurServices__wrapper_items_item__desc">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.description,
                        }}
                      ></div>
                    </div>
                  </CSSTransition>
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </div>
  );
};
