import React, { useEffect, useState } from "react";
import cs from "classnames";
import { useRouter } from "lib/hooks/useRouter";
import useLockedBody from "lib/hooks/useLockedBody";

import { Icon } from "components/shared/Icon/Icon";
import { NavigationBarItem } from "components/shared/NavigationBarItem/NavigationBarItem";

import "./NavigationBar.scss";
import { useTranslation } from "react-i18next";

export const NavigationBar = () => {
  const { i18n, t } = useTranslation("labels");
  const [locked, setLocked] = useLockedBody(false);
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const router = useRouter();

  useEffect(() => {
    setShowMobileMenu(false);
  }, [router.location]);

  const handleClick = () => {
    setLocked(!locked);
    setShowMobileMenu((prevState) => !prevState);
  };

  const closeMobileMenu = () => {
    setLocked(false);
    setShowMobileMenu(false);
  };

  const toggleLanguage = async () => {
    const lang = i18n.language === "sq" ? "en" : "sq";
    localStorage.setItem("profiti_language", lang);
    await i18n.changeLanguage(lang);
    window.location.reload();
  };

  return (
    <div className="NavigationBar">
      <ul className={cs("NavigationBar__list", showMobileMenu && "active")}>
        <NavigationBarItem
          page={t("Ballina")}
          to="/"
          onClick={closeMobileMenu}
        />
        <NavigationBarItem
          page={t("Rreth nesh")}
          to="/rreth-nesh"
          onClick={closeMobileMenu}
        />

        <NavigationBarItem
          page={t("Kategoritë")}
          to="/kategoritë"
          onClick={closeMobileMenu}
        />
        <NavigationBarItem
          page={t("Portfolio")}
          to="/portfolio"
          onClick={closeMobileMenu}
        />
        <NavigationBarItem
          page={t("Kontakti")}
          to="/kontakti"
          onClick={closeMobileMenu}
        />

        <li className="NavigationBarItem NavigationBarItem__line">|</li>

        <NavigationBarItem
          page={i18n.language === "sq" ? "English" : "Shqip"}
          onClick={toggleLanguage}
        />
      </ul>

      <div className="NavigationBar__mobile" onClick={handleClick}>
        <Icon
          className="NavigationBar__icon"
          icon={showMobileMenu ? "close" : "hamburgerMenu"}
        />
      </div>
    </div>
  );
};
