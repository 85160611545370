import React, { useEffect } from "react";

import { UIContextProvider } from "./lib/context/UIContext/UIContextProvider";
import { AuthContextProvider } from "./lib/context/AuthContext/AuthContextProvider";
import { ConfirmationContextProvider } from "./lib/context/ConfirmationContext/ConfirmationContextProvider";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { MainTemplate } from "components/shared/MainTemplate/MainTemplate";
//Partials
import { Header } from "./components/partials/Header/Header";
import { Footer } from "./components/partials/Footer/Footer";
import { Routes } from "./routes/Routes";
import { ToastContainer } from "react-toastify";
import "../src/i18n/i18n";

//Styles
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { useRouter } from "lib/hooks/useRouter";
import { getTranslations } from "api/General";
import i18next from "i18next";
import i18n from "i18next";
import { Triangle } from "react-loader-spinner";

const config = {
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function filterObjectsByKey(arr: any, keyToFilter: any) {
  const result = {};

  for (const obj of arr) {
    if (obj[keyToFilter]) {
      (result as any)[obj["key"]] = obj[keyToFilter];
    }
  }

  return result;
}

function App() {
  const { i18n } = useTranslation("labels");
  const router = useRouter();

  useEffect(() => {
    (async () => {
      const lang = localStorage.getItem("profiti_language") ?? "sq";
      await i18n.changeLanguage(lang);
      localStorage.setItem("profiti_language", lang);
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [router.location]);

  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer {...config} />
      <AuthContextProvider>
        <UIContextProvider>
          <ConfirmationContextProvider>
            <MainApp />
          </ConfirmationContextProvider>
        </UIContextProvider>
      </AuthContextProvider>
    </QueryClientProvider>
  );
}

function MainApp() {
  const { isLoading } = useQuery("getTranslations", getTranslations, {
    onSuccess: (data) => {
      const translations = filterObjectsByKey(data, i18next.language);
      i18next.addResourceBundle(i18n.language, "labels", translations);
    },
  });

  if (isLoading) {
    return (
      <div
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Triangle
          height="80"
          width="80"
          color="#000"
          ariaLabel="triangle-loading"
          visible
        />
      </div>
    );
  }

  return (
    <div className="App container">
      <Header />
      <MainTemplate>
        <Routes />
      </MainTemplate>
      <Footer />
    </div>
  );
}

export default App;
