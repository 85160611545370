import React from "react";
import { useQuery } from "react-query";

import { Container } from "components/Container/Container";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right.svg";
import { getCategoryPage, getSingleCategory } from "api/General";
import { Triangle } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { removeHTMLEntities } from "lib/helpers/removeHTMLEntities";

import "./SingleCategory.scss";

export const SingleCategory = (props: any) => {
  const slug = props.match?.params?.id;
  const {
    i18n: { language },
  } = useTranslation();
  const isEnglish = language === "en";
  const { data, isLoading, isError } = useQuery(
    ["getSingleCategory", slug],
    () => getSingleCategory(slug)
  );

  const {
    data: categoryData,
    isLoading: categoryIsLoading,
    error: categoryIsError,
  } = useQuery("getCategoryPage-single", getCategoryPage);

  if (isLoading || categoryIsLoading) {
    return (
      <div className="SingleCategory">
        <Container>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 100,
            }}
          >
            <Triangle
              height="80"
              width="80"
              color="#000"
              ariaLabel="triangle-loading"
              visible
            />
          </div>
        </Container>
      </div>
    );
  }

  if (isError || categoryIsError) {
    return (
      <div className="SingleCategory">
        <Container>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 100,
            }}
          >
            <p>
              {" "}
              {isEnglish
                ? "Something went wrong, please try again!"
                : "Dicka shkoi gabim, ju lutemi provoni përsëri!"}
            </p>
          </div>
        </Container>
      </div>
    );
  }

  const aboutUsData = categoryData?.data ?? {};

  return (
    <>
      <div className="SingleCategory">
        <div className="SingleCategory__wrapper">
          <div className="SingleCategory__wrapper_top">
            <div className="SingleCategory__wrapper_top--img">
              <img alt="img" src={data?.thumbnail} />
            </div>
            <div className="SingleCategory__wrapper_top--title">
              <h1>{data?.title}</h1>
            </div>
          </div>
          <Container>
            <div className="SingleCategory__wrapper_bottom">
              <h1>
                {isEnglish ? "General description" : "Përshkrim gjeneral"}
              </h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: removeHTMLEntities(data?.content).toString(),
                }}
              />
              <div className="SingleCategory__wrapper_bottom-items">
                {data?.externalLinks &&
                  data?.externalLinks?.map(
                    (
                      item: {
                        description: string;
                        external_link: string;
                        thumbnail: string;
                        title: string;
                      },
                      index: number
                    ) => {
                      return (
                        <div
                          key={`${item.title}-${index}`}
                          className="SingleCategory__wrapper_bottom-items--item"
                        >
                          <div className="SingleCategory__wrapper_bottom-items--item--left">
                            <img alt="img" src={item.thumbnail} />
                          </div>
                          <div className="SingleCategory__wrapper_bottom-items--item--middle">
                            <h1>{item?.title}</h1>
                            <p>{item?.description}</p>
                          </div>
                          <div
                            onClick={() =>
                              (window.location.href = item?.external_link)
                            }
                            className="SingleCategory__wrapper_bottom-items--item--right"
                          >
                            <ArrowRight style={{ width: 19 }} />
                          </div>
                        </div>
                      );
                    }
                  )}
              </div>
            </div>
          </Container>
        </div>
      </div>
      <div className="Footer__main__top">
        <Container>
          <div className="Footer__main__top__wrapper">
            <h1>{aboutUsData?.more_info?.title}</h1>
            <p>{aboutUsData?.more_info?.description}</p>
            <div className="Footer__main__top__wrapper_btns">
              <div className="Header--bottom--buttons">
                <button
                  onClick={() =>
                    (window.location.href =
                      aboutUsData?.more_info?.first_button_url)
                  }
                  className="Header--bottom--buttons"
                >
                  {aboutUsData?.more_info?.first_button_text}
                </button>
                <button
                  onClick={() =>
                    (window.location.href =
                      aboutUsData?.more_info?.second_button_url)
                  }
                  className="Header--bottom--buttons-rev"
                >
                  {aboutUsData?.more_info?.second_button_text}
                </button>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
