import React from "react";
import { Container } from "components/Container/Container";
import { SlideShow } from "components/SlideShow/SlideShow";

import "./TopSale.scss";

interface Props {
  categories: any;
  categoryTitle?: string;
  categoryDescription?: string;
}

export const TopSale = (props: Props) => {
  const categories = props;

  return (
    <div className="TopSale animate__animated animate__fadeIn">
      <Container>
        <SlideShow
          loading={false}
          items={categories.categories}
          title={categories?.categoryTitle || ""}
          redirectLink="tenxehta=true"
          description={categories?.categoryDescription}
        />
      </Container>
    </div>
  );
};
