import React from "react";
import { NavLink } from "react-router-dom";
import cs from "classnames";
import "./NavigationBarItem.scss";

interface Props {
  onClick: () => void;
  page: string;
  to?: string;
  className?: string;
}

export const NavigationBarItem = ({ onClick, page, to, className }: Props) => {
  return (
    <li className="NavigationBarItem" onClick={onClick} key={page}>
      <NavLink
        className={cs("NavigationBarItem__link", className)}
        to={to || ""}
        activeClassName="NavigationBarItem__active"
        exact
      >
        {page}
      </NavLink>
    </li>
  );
};
