import React from "react";
import { Container } from "components/Container/Container";
import { ContactForm } from "components/Contact/ContactForm";
import { getContactUsPage } from "api/General";
import { useQuery } from "react-query";

import { Triangle } from "react-loader-spinner";

import "./ContactUs.scss";
import { useTranslation } from "react-i18next";

interface ContactPage {
  title: string;
  data: {
    title: string;
    contact_description: string;
    contact_title: string;
    description: string;
    free_trail_button_title: string;
    free_trail_description: string;
    free_trail_title: string;
    free_trail_url: string;
    info: {
      title: string;
      description: string;
      info: string;
      icon: string;
    }[];
  };
}

export const ContactUs = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const isEnglish = language === "en";
  const { data, isLoading, isError } = useQuery(
    "getContactUsPage",
    getContactUsPage
  );

  if (isLoading) {
    return (
      <div>
        <Container>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Triangle
              height="80"
              width="80"
              color="#000"
              ariaLabel="triangle-loading"
              visible
            />
          </div>
        </Container>
      </div>
    );
  }

  if (isError) {
    return (
      <div>
        <Container>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>
              {isEnglish
                ? "Something went wrong, please try again!"
                : "Dicka shkoi gabim, ju lutemi provoni përsëri!"}
            </p>
          </div>
        </Container>
      </div>
    );
  }

  const contactData = data as ContactPage;

  return (
    <div className="ContactUs">
      <Container>
        <div className="ContactUs__wrapper">
          <div className="ContactUs__wrapper__top">
            <span>{contactData?.title}</span>
            <h1>{contactData?.data?.title}</h1>
            <p>{contactData?.data?.description}</p>
          </div>
          <div className="ContactUs__wrapper__middle">
            {contactData?.data?.info?.map((item, index) => {
              return (
                <div
                  key={`${item.title}-${index}`}
                  className="ContactUs__wrapper__middle--item"
                >
                  <div className="ContactUs__wrapper__middle--item-icon">
                    <img
                      style={{ width: 22, height: 18, objectFit: "contain" }}
                      alt="img"
                      src={item.icon}
                    />
                  </div>
                  <span>{item?.title}</span>
                  <p>{item?.description}</p>
                  <label>{item?.info}</label>
                </div>
              );
            })}
          </div>
        </div>
      </Container>
      <div className="Contact__wrapper-right">
        <h1>{contactData?.data?.contact_title}</h1>
        <p>{contactData?.data?.contact_description}</p>
        <ContactForm />
      </div>
    </div>
  );
};
