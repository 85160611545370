import React from "react";
import { useQuery } from "react-query";
import { getHomePage } from "api/General";
import { Contact } from "components/Contact/Contact";
import { TopSale } from "components/TopSale/TopSale";
import { Partners } from "components/Partners/Partners";
import { BrandsIcons } from "components/BrandsIcons/BrandsIcons";
import { OurServices } from "components/OurServices/OurServices";
import { Container } from "components/Container/Container";
import { Triangle } from "react-loader-spinner";
import { useTranslation } from "react-i18next";

import "./Home.scss";

export const Home = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const isEnglish = language === "en";
  const { data, isLoading, isError } = useQuery("getHomePage", getHomePage);

  if (isLoading) {
    return (
      <div className="Home">
        <Container>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Triangle
              height="80"
              width="80"
              color="#000"
              ariaLabel="triangle-loading"
              visible
              wrapperStyle={{ marginBottom: "10vh" }}
            />
          </div>
        </Container>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="Home">
        <Container>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>
              {isEnglish
                ? "Something went wrong, please try again!"
                : "Dicka shkoi gabim, ju lutemi provoni përsëri!"}
            </p>
          </div>
        </Container>
      </div>
    );
  }

  const categories = data?.categoires ?? [];
  const contact = data?.contact ?? {};
  const ourServices = data?.services ?? {};
  const partners = data?.info ?? {};
  const brands = data?.partners ?? [];
  const brandsTitle = data?.partner_title ?? "Partnerët tanë";

  return (
    <div className="Home">
      <TopSale
        categoryTitle={data?.category_title}
        categoryDescription={data?.category_description}
        categories={categories}
      />
      <BrandsIcons title={brandsTitle} brands={brands} />
      <Partners partners={partners} />
      <OurServices services={ourServices} />
      <Contact contact={contact} />
    </div>
  );
};
