import React from "react";
import cs from "classnames";

import "./Container.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const Container = (props: Props) => {
  return (
    <div className={cs("Container", props.className)}>{props.children}</div>
  );
};
