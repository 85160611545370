// @ts-nocheck
import React from "react";
import { useHistory } from "react-router";
import { Logo } from "components/Logo/Logo";
import { Container } from "components/Container/Container";
import * as Yup from "yup";
import { Col, Form, FormFeedback, FormGroup, Input, Row } from "reactstrap";
import { toast } from "react-toastify";
import { useFormik } from "formik";

import { useRouter } from "lib/hooks/useRouter";
import { useSubscribe } from "lib/hooks/mutations/useSubscribe";

//styles
import "./Footer.scss";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getAboutUsPage } from "api/General";

export const Footer = () => {
  const { data } = useQuery("getAboutUsPage", getAboutUsPage);

  const free_trial_title = data?.data?.free_trail_title;
  const free_trail_description = data?.data?.free_trail_description;
  const free_trail_button_title = data?.data?.free_trail_button_title;

  const {
    t,
    i18n: { language },
  } = useTranslation("labels");

  const router = useRouter();
  const history = useHistory();
  const subscribe = useSubscribe();

  const isEnglish = language === "en";

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email()
        .required(
          isEnglish ? "Email is required!" : "Ju lutemi shënoni emailin tuaj!"
        ),
    }),
    onSubmit: async (values) => {
      const data = {
        email: values.email,
      };
      subscribe.mutate(data, {
        onSuccess: () => {
          toast.success(
            isEnglish
              ? "You have successfully subscribed!"
              : "Ju jeni abonuar me sukses!"
          );
          formik.resetForm();
        },
        onError: () => {
          toast.success(
            isEnglish
              ? "Something went wrong, please try again!"
              : "Dicka shkoi gabim, ju lutemi provoni përsëri!"
          );
          formik.resetForm();
        },
      });
    },
  });

  return (
    <div className="Footer__main__wrapper">
      <div className="Footer__wrapper_top cst-bck">
        <Container>
          <div className="Footer__wrapper_top_wr">
            <div className="Footer__wrapper_top_labels">
              <h1>{t("abonohu_title")}</h1>
              <p>{t("abonohu_update")}</p>
            </div>

            <div className="Footer__wrapper_top_btn">
              <div className="Header--bottom--buttons">
                <Form onSubmit={() => formik.handleSubmit()}>
                  <Row>
                    <Col xs="12" sm="12">
                      <FormGroup>
                        <Input
                          id="email"
                          type="email"
                          name="email"
                          placeholder="Email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          className="form-control-custom"
                          invalid={
                            !!formik.errors.email && formik.touched.email
                          }
                        />
                        <FormFeedback>{formik.errors.email}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col xs="12">
                      <FormGroup>
                        <div className="Header--bottom--buttons">
                          <button
                            type="submit"
                            onClick={() => formik.handleSubmit()}
                            disabled={subscribe.isLoading}
                          >
                            {t("abonohu")}
                          </button>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="Footer__main">
        <footer className="Footer">
          <Container>
            <div className="Footer__wrapper">
              <div className="Footer__divider" />
              {router.location.pathname !== "/contact" && (
                <>
                  <div className="Footer__wrapper_top">
                    <div className="Footer__wrapper_top_labels">
                      <h1>{free_trial_title}</h1>
                      <p>{free_trail_description}</p>
                    </div>

                    <div className="Footer__wrapper_top_btn">
                      <div className="Header--bottom--buttons">
                        <button
                          onClick={() => history.push("/kontakti")}
                          // type="submit"
                          disabled={false}
                        >
                          {free_trail_button_title}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="Footer__divider" />
                </>
              )}
              <div className="Footer__wrapper_middle">
                <Logo />
                <div className="Footer__wrapper_middle_tabs">
                  <span onClick={() => history.push("/")}>{t("Ballina")}</span>
                  <span onClick={() => history.push("/rreth-nesh")}>
                    {t("Rreth nesh")}
                  </span>
                  <span onClick={() => history.push("/kategoritë")}>
                    {t("Kategoritë")}
                  </span>
                  <span onClick={() => history.push("/portfolio")}>
                    {t("Portfolio")}
                  </span>
                  <span onClick={() => history.push("/kontakti")}>
                    {t("Kontakti")}
                  </span>
                </div>
              </div>
              <div className="Footer__divider" />
              <div className="Footer__wrapper_bottom">
                <div className="Footer__wrapper_bottom-left">
                  {isEnglish
                    ? "© 2023 Profiti Group. All Rights Reserved."
                    : "© 2023 Profiti Group. Të gjitha të drejtat e rezervuara."}
                </div>
                <div className="Footer__wrapper_bottom-right">
                  <a href="/terms">{isEnglish ? "Terms" : "Kushtet"}</a>
                  <a href="/privacy">{isEnglish ? "Privacy" : "Privatësia"}</a>
                  <a href="/cookies">{isEnglish ? "Cookies" : "Cookies"}</a>
                </div>
              </div>
            </div>
          </Container>
        </footer>
      </div>
    </div>
  );
};
