import React, { useRef } from "react";
import { Container } from "components/Container/Container";
import { ThemeLink } from "components/ThemeLink/ThemeLink";
import { Image } from "components/Image/Image";

import { BrandsLoader } from "./BrandsLoader";
import { removeHTMLEntities } from "lib/helpers/removeHTMLEntities";
import Flickity from "react-flickity-component";

import "flickity/dist/flickity.css";
import "./BrandsIcons.scss";

export interface HomeVendorProps {
  logo: string;
  name: string;
  slug: string;
  url: string;
}

interface Props {
  title: string;
  brands: any[];
}

export const BrandsIcons = (props: Props) => {
  const { brands, title } = props;
  const flickity = useRef<Flickity | null>(null);

  return (
    <div className="BrandsIcons">
      <Container>
        <div className="BrandsIcons__wrapper">
          <div className="BrandsIcons__wrapper_title">
            <span>{title}</span>
          </div>
          <div className="BrandsIcons__wrapper--flickity">
              {/*  @ts-ignore */}

            <Flickity
              flickityRef={(c) => (flickity.current = c)}
              className="carousel"
              elementType="div"
              options={{
                wrapAround: false,
                draggable: true,
                cellAlign: "center",
                freeScroll: true,
                resize: true,
                pageDots: false,
                prevNextButtons: false,
                contain: true,
                bgLazyLoad: true,
                lazyLoad: 1,
                autoPlay: true,
              }}
              disableImagesLoaded={false}
            >
              {brands?.map((item: HomeVendorProps, index) => {
                return (
                  <li
                    onClick={() => (window.location.href = item.url)}
                    className="BrandsIcons__list__item"
                    key={`${item?.slug}-home-brands-listing-${index}`}
                  >
                    <ThemeLink to={`/`}>
                      {item.logo ? (
                        <Image alt={item.name} src={item.logo} />
                      ) : (
                        <span className="BrandsIcons__list__item__name">
                          {removeHTMLEntities(item?.name)}
                        </span>
                      )}
                    </ThemeLink>
                  </li>
                );
              })}
            </Flickity>
          </div>
        </div>
      </Container>
    </div>
  );
};

export const BrandsIconsLoader = (props: { total: number }) => {
  const items = [];

  while (items.length <= props.total) {
    items.push(<BrandsLoader key={`${items.length}-home-brands-listing`} />);
  }

  return (
    <div className="BrandsIcons">
      <ul className="BrandsIcons__list">{items}</ul>
    </div>
  );
};
