import cs from "classnames";
import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import { RotatingLines } from "react-loader-spinner";
import "./Button.scss";

export interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  loading?: boolean;
}

export const Button = (props: ButtonProps) => {
  const { className, children, loading, ...rest } = props;
  return (
    <button className={cs("Button", className)} {...rest}>
      {loading ? (
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="30"
          visible
        />
      ) : (
        children
      )}
    </button>
  );
};
