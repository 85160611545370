import { useContext } from "react";
import {
  __RouterContext,
  RouteComponentProps,
  StaticContext,
} from "react-router";

export function useRouter() {
  return useContext(
    __RouterContext as unknown as React.Context<
      RouteComponentProps<any, StaticContext, any>
    >
  );
}
