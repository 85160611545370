import React from "react";

import { Image } from "components/Image/Image";
import { Container } from "components/Container/Container";

import { ContactForm } from "./ContactForm";
import { useWindowWidth } from "lib/hooks/useWindowWidth";

import "./Contact.scss";

interface Props {
  contact: {
    description: string;
    image: string;
    title: string;
  };
}

export const Contact = (props: Props) => {
  const { contact } = props;
  const windowWidth = useWindowWidth();
  const isTablet = windowWidth <= 1024;

  return (
    <div className="Contact">
      <Container>
        <div className="Contact__wrapper">
          {!isTablet && (
            <div className="Contact__wrapper-left">
              <Image alt="img" src={contact?.image} />
            </div>
          )}
          <div className="Contact__wrapper-right">
            {!isTablet && (
              <>
                <h1>{contact?.title}</h1>
                <p>{contact?.description}</p>
              </>
            )}
            <ContactForm />
          </div>
        </div>
      </Container>
    </div>
  );
};
